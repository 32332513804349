import moment from "moment";
import { getFreeExamParticipants } from "../../../services/api/queries/freeExam";

const freeExamParticipatnsListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Phone Number",
        accessor: "phone_number",
      },
      {
        Header: "Last Attempt",
        accessor: "last_attempt_date",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY"),
      },
      {
        Header: "Exam",
        accessor: "free_exam.name",
      },
    ],
    actions: [],
    queryService: getFreeExamParticipants,
  };
};

export default freeExamParticipatnsListViewMeta;
